.video-docker video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.video-docker::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.video-content {
  z-index: 2;
}
.close-modal {
  display: block;
  position: relative;
  top: 2.2rem;
  left: -0.5rem;
  z-index: 9999;
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  color: #000;
  background-color: white;
  opacity: 0.5;
  border-radius: 50%;
  float: right;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
}
.modalVideo {
  width: 80%;
  max-width: 1536px;
  height: auto;
  margin: auto;
}
.book-card:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(to top, rgba(0, 0, 0, 0.4), transparent);
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 15px;
  right: 10px;
  top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
}
.bm-item-list {
  overflow-y: hidden;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #fff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.loader {
  border-top-color: #000;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

/*Agent Bio*/
.agentNameWrapper {
  min-height: 98px;
  display: block;
  width: 100%;
}

.agentName,
.agentBio,
.divTravelSpecialties,
.divTravelExperiences {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.addlInfo {
  margin-top: 20px;
  padding: 20px 0 20px 0;
  border-top: 1px solid #ebebeb;
}

.agentName h1 {
  font-size: 35pt;
  font-weight: 300;
  color: #f48024;
  text-transform: uppercase;
}

.agentBio p,
.travelSpecialtyTeaser,
.experience,
.agentReview,
.bioInfo,
.agentTestimonal p {
  line-height: 25px;
}

.travelSpecialty {
  margin: 20px 0;
  clear: both;
}

.travelSpecialtyImg,
.imgTravelImage {
  float: left;
  margin: 10px 20px 0 0;
  min-height: 105px;
  font-style: italic;
  text-align: center;
  font-size: 12px;
  background: #ebebeb;
  padding: 5px;
}

.travelSpecialtyImg img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.travelSpecialtyImg img:hover {
  opacity: 0.5;
}
.imgTravelImage img {
  width: 256px;
}

.travelSpecialtyImg img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  /*width: 100%;*/
  width: 256px;
}
.gm-style-iw-t {
  right: 0px;
  bottom: 43px !important;
}

.image-gallery-icon {
  scale: 50% !important;
  top: 35% !important;
  padding: 0px !important;
}
.image-gallery-left-nav {
  left: -80px !important;
}
.image-gallery-right-nav {
  right: -80px !important;
}

.image-gallery-thumbnail {
  width: 70px !important;
}

.large-hero {
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  height: 15rem;
  width: 100%;
}

/* Blog Detail */
.blog-summary-thumbnail-image {
  width: 307px;
  height: 349px;
  object-fit: cover;
}

.ItineraryMargin {
  margin: 0px 0px 10px;
}
.CaptionMargin {
  margin: 0px 0px 15px;
}
.ImageBlock /*  Removing inline css to support global styling. */ {
  display: block;
  /* margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px; */
  width: 100%;
  margin: 0px 0px 8px;
}
.CorrectContainer {
  margin: 15px auto; /*  Changed from 50px to 15px  */
  /* max-width: 920px;  */
}
.ExtraPadding /*  Adding padding. The additional bottom padding helps with responsive spacing. */ {
  padding: 10px 10px 25px;
}

.InsertTemplate /*  Styling of instructional text */ {
  display: none;
  visibility: hidden;
}

.ButtonCenter {
  text-align: center;
  margin: 20px auto;
}

.pure-g [class*='pure-u'] {
  font-family: 'Avenir', sans-serif !important;
}
.divBlogStory,
.divPromotionBody {
  font-size: 25px;
  font-weight: 350;
  color: #000000;
  line-height: 140%;
  padding-bottom: 20px !important;
}
/* Rarely Used */

.divBlogStory h1,
.divPromotionBody h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
}
/* Featured Sailings */

.divBlogStory h2,
.divPromotionBody h2 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
/* Sailing Name */

.divBlogStory h3,
.divPromotionBody h3 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
/* Style Available */

.divBlogStory h4,
.divPromotionBody h4 {
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
/* Caption */

.divBlogStory h5,
.divPromotionBody h5 {
  font-size: 12px;
  font-weight: 500;
  padding-right: 10px !important;
  text-align: right;
  line-height: 1;
}
/* Terms & Conditions */

.divBlogStory h6,
.divPromotionBody h6 {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 1.25;
}
/* Style Available */

.divBlogStory h7,
.divPromotionBody h7 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.25;
}
/* Paragraph */
.divBlogStory ul,
.divPromotionBody ul,
.divBlogStory li,
.divPromotionBody li {
  /* font-size: unset; */
  list-style-type: disc;
  margin-left: 30px;
}
.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}

@media (min-width: 640px) {
  .large-hero {
    height: 26rem;
  }
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/Avenir/avenirltstd-roman.woff');
}
@font-face {
  font-family: 'Avenir-bold';
  src: url('../public/fonts/Avenir/AvenirLTStd-Black.otf');
}
@font-face {
  font-family: 'Avenir-lt-Std';
  src: url('../public/fonts/Avenir/avenirltstd-book.woff');
}
@font-face {
  font-family: 'Avenir-medium';
  src: url('../public/fonts/Avenir/Avenir Book.ttf');
}
/* @font-face {
  font-family: 'ivypresto-display';
  src: url('https://use.typekit.net/rzq1blc.css');
} */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: Avenir;
  }

  h1 {
    @apply text-2xl font-bold;
  }

  h2 {
    @apply text-xl font-bold;
  }

  h3 {
    @apply text-lg font-bold;
  }

  h4 {
    @apply text-xl leading-[26px];
  }

  p {
    @apply my-3;
  }
}

@layer components {
  .hidden-tw {
    @apply hidden;
  }
  .sf-form textarea,
  input,
  select {
    @apply bg-gtc4 p-3 text-base h-12;
  }
  .divBlogStory p,
  .divPromotionBody p {
    @apply tracking-wider leading-9 text-xl md:text-lg font-normal font-[Avenir-lt-Std];
  }
  .divBlogStory ul,
  .divPromotionBody ul,
  .divBlogStory li,
  .divPromotionBody li {
    @apply tracking-wider leading-9 text-xl md:text-lg font-normal font-[Avenir-lt-Std];
  }
  .default-btn {
    @apply w-fit py-2 font-normal text-center 
    text-white bg-black border
     border-black 
    hover:font-semibold  
    text-2xl
    px-6 hover:cursor-pointer;
  }
  .light-btn {
    @apply px-6 py-2 bg-white border border-gray-300  hover:bg-gray-200 w-[200px] text-[16px];
  }
  .div-btn-wrapper {
    @apply bg-black text-white text-base w-[164px] h-[50px] flex justify-center items-center;
  }
  .dark-btn {
    @apply px-6 py-2 bg-black border border-black  hover:bg-gray-900 w-[200px] h-[50px] text-base text-white;
  }
  .text-20-8 {
    @apply text-[16px] leading-[20.8px];
  }
  .text-31-2 {
    @apply text-[24px] leading-[31.2px] font-[Avenir] font-medium;
  }
  .desk-container {
    @apply max-w-[1440px];
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap');

.radio {
  margin: 0.5rem;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: white;
        border-radius: 100%;
        border: 2px solid #999a9b;
        display: inline-block;
        width: 1em;
        height: 1em;
        position: relative;
        top: 0.25em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 150ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: #999a9b;
          box-shadow: inset 0 0 0 4px #999a9b;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #999a9b;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #999a9b;
          border-color: darken(#999a9b, 25%);
          background: darken(#999a9b, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

/* Position pagination dots at the bottom-center */
.swiper-pagination {
  bottom: -5px !important;
  z-index: 1000 !important;
  position: relative !important;
}

/* Customize the bullet color */
.swiper-pagination-bullet {
  background-color: #007aff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background-color: #000000 !important;
}
